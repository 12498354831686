@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&family=Poppins:wght@300;400&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-anchor: none;
}

/* font-family: 'Poppins', sans-serif; */
/* font-family: 'Playfair Display', serif; */

/* navbar css */
.navbar {
  font-family: "lato", sans-serif;
  font-size: 14px;
  line-height: 26px;
}

.nav-link {
  font-weight: 500 !important;
}

.active {
  border-bottom: 2px solid black;
}

.navbar-brand {
  font-weight: 700;
  font-family: "roboto", sans-serif;
  letter-spacing: 3px;
}

.search-form {
  margin-right: 10px;
  margin-top: 4px;
}
.search-form input {
  border: none;
  border-radius: 0;
}
.search-form button {
  background-color: #000000;
  border-radius: 0;
  color: white;
}

/* =============================
==========footer============= */

.footer {
  color: white;
  padding: 25px;
  background: #000000; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #434343,
    #000000
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #434343, #000000);
}

.footer a {
  text-decoration: none;
  color: white;
  padding: 10px;
}

.footer a:hover {
  color: #ffefba;
  border-bottom: 1px solid #ffefba;
}
/* =================================
======== page not found css ======= */
.pnf {
  display: flex;
  min-height: 65vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pnf-title {
  font-size: 100px;
  font-weight: 700;
}

.pnf-heading {
  font-weight: normal;
}

.pnf-btn {
  color: black;
  border: 1px solid black;
  text-decoration: none;
  padding: 10px;
  margin-top: 10px;
}
.pnf-btn:hover {
  background-color: black;
  color: white;
}
/* ========================================= */
/* =========contact us ========= */
.contactus {
  margin: 0;
  padding: 0;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ==================== */
.product-link {
  text-decoration: none !important;
  color: black !important;
}

.cat-btn {
  padding: 40px 0px;
  font-size: 20px;
  text-transform: uppercase;
}
.cat-btn:hover {
  background-color: #434343;
  color: white;
}
/* ================
====dashboard
=================== */

.dashboard {
  margin-top: 100px !important;
}

.dashboard-menu h4 {
  background-color: #434343 !important;
  color: white;
  padding: 20px 0px;
}